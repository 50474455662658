import React, { useState, useEffect } from "react";
import axios from "axios";
import BGImg1 from "../../assets/img/bg/about-bg.png";
import { Link } from "react-router-dom";
import { baseURL } from "../../App";
import { useGlobalState } from "../../useGlobalState";

const About = () => {
  // const [about, setAbout] = useState(null);
  const [hakkimizda, setHakkimizda] = useState(null);

  useEffect(() => {
    try {
      axios
        .get(`${baseURL}items/hakkimizda?fields=*.*`)
        .then((response) => {
          // Verinin doğru formatta olup olmadığını kontrol et
          if (response.data) {
            // Veriyi state'e set etme
            setHakkimizda(response.data.data);
          } else {
            console.error("Invalid data format received from Directus.");
          }
        })
        .catch((error) => {
          console.error("Error fetching data from Directus:", error);
        });
    } catch (error) {
      console.error("An error occurred in useEffect:", error);
    }
  }, []);

  const { globalState, setGlobalState } = useGlobalState();

  return (
    <>
      <section
        className="about-area about-p pt-120 pb-120 p-relative fix"
        style={{
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundImage: `url(${BGImg1})`,
        }}
      >
        <div className="container">
          {hakkimizda && (
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div
                  className="s-about-img p-relative  wow fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay=".4s"
                >
                  {/* <img src={`${baseURL}assets/${about.image?.id}`} alt="" /> */}
                  <img src={`${baseURL}assets/${hakkimizda.resim?.id}`} alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div
                  className="about-content s-about-content  wow fadeInRight  animated"
                  data-animation="fadeInRight"
                  data-delay=".4s"
                >
                  <div className="about-title second-title pb-25">
                    {/* <h5>
                      {
                        about.translations?.filter(
                          (b) => b.languages_code === globalState.lang
                        )[0]?.title
                      }
                    </h5> */}
                    <h5>
                      {
                        hakkimizda.translations?.filter(
                          (b) => b.languages_code === globalState.lang
                        )[0]?.baslik
                      }
                    </h5>
                    {/* <h3>
                      {
                        about.translations?.filter(
                          (b) => b.languages_code === globalState.lang
                        )[0]?.text
                      }
                    </h3> */}
                    <h3>
                      {
                        hakkimizda.translations?.filter(
                          (b) => b.languages_code === globalState.lang
                        )[0]?.altbaslik
                      }
                    </h3>
                  </div>
                  {/* <p>
                    {
                      about.translations?.filter(
                        (b) => b.languages_code === globalState.lang
                      )[0]?.metin
                    }
                  </p> */}

                  <p>
                    {
                      hakkimizda.translations?.filter(
                        (b) => b.languages_code === globalState.lang
                      )[0]?.yazi
                    }
                  </p>
                  <div className="slider-btn mt-20">
                    <Link to={hakkimizda.butonlink} className="btn ss-btn smoth-scroll">
                      {
                        hakkimizda.translations?.filter(
                          (b) => b.languages_code === globalState.lang
                        )[0]?.butonyazi
                      }
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default About;
