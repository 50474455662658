import React from "react";
import Bredcrumb from "../../HomeMain/Bredcrumb";
import { Link, useParams } from "react-router-dom";

import { useEffect } from "react";
import { useState } from "react";
import { baseURL } from "../../../App";
import axios from "axios";
import { useGlobalState } from "../../../useGlobalState";


const Main = () => {
  const { url } = useParams();
  const [iletisim, setIletisim] = useState([]);

  const [hizmet, setHizmet] = useState(null);
  const [hizmetler, setHizmetler] = useState(null);

  const { globalState, setGlobalState } = useGlobalState()



  // Tüm hizmetleri çekmek için kullanılan useEffect
  useEffect(() => {
    const getHizmet = async () => {
      try {
        const res = await axios.get(`${baseURL}items/hizmetler?fields=*.*`);
        setHizmetler(res.data.data);
        const hiz = res.data.data.filter(e => e.link == "/" + url)[0]
        setHizmet(hiz)

        // Diğer kodlar...
      } catch (error) {
        console.error("Hizmetler getirilirken bir hata oluştu:", error);
      }
    };

    getHizmet();
  }, [url]);



  const fetchContactData = async () => {
    try {
      const response = await axios.get(`${baseURL}items/iletisim?fields=*.*`);
      // Verinin doğru formatta olup olmadığını kontrol et
      if (response.data) {
        // Veriyi state'e set etme
        setIletisim(response.data.data);
      } else {
        console.error("Invalid data format received from Directus.");
      }
    } catch (error) {
      console.error("Error fetching data from Directus:", error);
    }
  };

  useEffect(() => {
    fetchContactData();
  }, []);


  return (
    hizmet && hizmetler && (
      <>
        <Bredcrumb title={hizmet && hizmet.translations?.filter(e => e.languages_code == globalState.lang)[0]?.baslik} subtitle={hizmet && hizmet.translations?.filter(e => e.languages_code == globalState.lang)[0]?.baslik} />



        <div className="about-area5 about-p p-relative">
          <div className="container pt-120 pb-90">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4 order-1">
                <aside className="sidebar services-sidebar">
                  <div className="sidebar-widget categories">
                    <div className="widget-content">
                      <ul className="services-categories">
                        {/* <li className="active">
                            <Link to={e?.url}>{e?.translations?.services.filter(b => b.languages_code === globalState.lang)[0]?.link}</Link>
                          </li> */}

                        {hizmetler && hizmetler.map((e) => (
                          <li key={e.id}>
                            <Link
                              to={"/hizmetler" + e?.link}
                              className={`${e?.link === "/" + url ? "active" : ""}`}
                            >
                              {e?.translations?.filter(b => b.languages_code === globalState.lang)[0]?.baslik}
                              <i className="fa fa-arrow-right"></i>
                            </Link>
                          </li>
                        ))}

                      </ul>
                    </div>
                  </div>
                  <div
                    className="service-detail-contact wow fadeup-animation"
                    data-wow-delay="1.1s"
                  >
                    <h3 className="h3-title">
                      {iletisim && iletisim.translations?.filter(e => e.languages_code == globalState.lang)[0]?.bilgi}
                    </h3> <br />
                    <Link
                      to={`https://wa.me/${iletisim && iletisim.translations?.filter(e => e.languages_code === globalState.lang)[0]?.no}`}
                      title="WhatsApp iletişim"
                    >
                      {iletisim && iletisim.translations?.filter(e => e.languages_code == globalState.lang)[0]?.no}
                    </Link>
                  </div>
                </aside>
              </div>



              <div className="col-lg-8 col-md-12 col-sm-12 order-2">
                <div className="service-detail">
                  <div className="content-box">
                    <h4>{hizmet.translations?.filter(b => b.languages_code === globalState.lang)[0]?.baslik} </h4>

                    <p>
                      {hizmet.translations?.filter(b => b.languages_code === globalState.lang)[0]?.yazi_2}
                    </p>
                    <div className="two-column">
                      <div className="row">
                        <div className="image-column col-xl-6 col-lg-12 col-md-12">
                          <figure className="image">
                            <img src={`${baseURL}assets/${hizmet.resim1?.id}`} alt="" />

                          </figure>
                        </div>
                        <div className="text-column col-xl-6 col-lg-12 col-md-12">
                          <figure className="image">
                            <img src={`${baseURL}assets/${hizmet.resim2?.id}`} alt="" />

                          </figure>
                        </div>
                      </div>
                    </div>
                    <h4>{hizmet.translations?.filter(b => b.languages_code === globalState.lang)[0]?.yazi_3} </h4>
                    <p>
                      {hizmet.translations?.filter(b => b.languages_code === globalState.lang)[0]?.metin}
                    </p>

                    {/* <p>
                      {hizmet.translations?.filter(b => b.languages_code === globalState.lang)[0]?.yazi3}
                    </p> */}

                    <div className="two-column">
                      <div className="row">
                        <div className="image-column col-xl-12 col-lg-12 col-md-12">
                          <figure className="image">
                            <img src={`${baseURL}assets/${hizmet.resim3?.id}`} alt="" />

                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Main;
