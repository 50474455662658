import BGImg3 from "../../assets/img/bg/ap-bg.png";
import React, { useState, useEffect } from "react";
import Img from "../../assets/img/bg/open-box-img.png";
import Map from "../Mencontact/Map";
import axios from "axios";
import { lang } from "../../App";
import { baseURL } from "../../App";

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useGlobalState } from "../../useGlobalState";





const Appointment = () => {

  const { globalState, setGlobalState } = useGlobalState();

  const [iletisim, setIletisim] = useState([]);
  const [saatler, setSaatler] = useState([]);

  const fetchContactData = async () => {
    try {
      const response = await axios.get(`${baseURL}items/iletisim?fields=*.*`);
      // Verinin doğru formatta olup olmadığını kontrol et
      if (response.data) {
        // Veriyi state'e set etme
        setIletisim(response.data.data);
      } else {
        console.error("Invalid data format received from Directus.");
      }
    } catch (error) {
      console.error("Error fetching data from Directus:", error);
    }
  };

  const fetchSaatData = async () => {
    try {
      const response = await axios.get(`${baseURL}items/saatler?fields=*.*`);
      // Verinin doğru formatta olup olmadığını kontrol et
      if (response.data) {
        // Veriyi state'e set etme
        setSaatler(response.data.data);
      } else {
        console.error("Invalid data format received from Directus.");
      }
    } catch (error) {
      console.error("Error fetching data from Directus:", error);
    }
  };

  useEffect(() => {
    fetchContactData();
    fetchSaatData();
  }, []);




  return (
    <>
      <section
        id="booking"
        className="booking-area p-relative pt-120 pb-120"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${BGImg3})`,
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="contact-bg">
                <div className="section-title center-align">
                  <h5>{iletisim && iletisim.translations?.filter(e => e.languages_code == globalState.lang)[0]?.baslik}</h5>
                  <h2>{iletisim && iletisim.translations?.filter(e => e.languages_code == globalState.lang)[0]?.altbaslik}</h2>
                  <p>{iletisim && iletisim.translations?.filter(e => e.languages_code == globalState.lang)[0]?.yazi}</p>
                </div>

                <Map />

              </div>
            </div> <br />
            <div className="col-lg-6">
              <div className="opening-time">
                <div className="open-img">
                  <img src={Img} alt="icon01" />
                </div>
                {/* <h3>Çalışma Saatlerimiz</h3> */}
                <h3>{saatler && saatler.translations?.filter(e => e.languages_code == globalState.lang)[0]?.baslik}</h3>

                <div className="text">
                  {/* <div className="left-text">Pazartesi - Cuma:</div>
                  <div className="right-text">09:00  – 20:00 </div> */}
                  {saatler && saatler.translations?.filter(e => e.languages_code == globalState.lang)[0]?.altbaslik}
                </div>
                <div className="text">
                  {saatler && saatler.translations?.filter(e => e.languages_code == globalState.lang)[0]?.yazi}

                </div>
                <div className="text">
                  {/* <div className="left-text">Pazar:</div>
                  <div className="right-text">09:00  – 18:00 </div> */}
                </div>
                <div className="discount">
                  {/* Check out seasonal discounts for best offers. */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Appointment;
