import React, { useState } from "react";
import Bredcrumb from "../../HomeMain/Bredcrumb";
import First from "../Menblog/First";


const Main = () => {
  
  return (
    <>
      <Bredcrumb title="Blog" subtitle="Blog" />
      <First/>
    </>
  );
};

export default Main;
