import React from "react";
import Bredcrumb from "../../HomeMain/Bredcrumb";
import First from "../Menshopdeatils/First";
import Second from "../Menshopdeatils/Second";

const Main = () => {
  return (
    <>
      <Bredcrumb title="Shop Details" subtitle="Shop Details" />
      <First/>
      <Second/>
    </>
  );
};

export default Main;
