import React from 'react'

function First() {
    return (
        <>
            <section className="team-area-content">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="col-lg-3 col-md-12 col-sm-12 text-center">
                                <div className="team-img-box">
                                    <img src="assets/img/team/team01.png" alt="img" />
                                </div>
                            </div>
                            <div className="text-column col-lg-9 col-md-12 col-sm-12">
                                <div className="s-about-content pl-30 wow fadeInRight" data-animation="fadeInRight" data-delay=".2s">
                                    <p>
                                        Pleasure and praising pain was born and I will give you a
                                        complete account of the systems, and expound the actually
                                        teachings of the great explorer of the truth, the
                                        master-builder of human uts happiness. No one rejects,
                                        dislikes, or avoids pleasure itself, because it is pleasure,
                                        but because those who do not know how to pursue pleasure
                                        rationally Nor who loves or pursues or desires to obtain
                                        pain of itself.
                                    </p>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="skills-content s-about-content mt-20">
                                                <div className="skills">
                                                    <div className="skill mb-30">
                                                        <div className="skill-name">Design</div>
                                                        <div className="skill-bar">
                                                            <div
                                                                className="skill-per"
                                                                id="80"
                                                                style={{ width: "80%" }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                    <div className="skill mb-30">
                                                        <div className="skill-name">Easy Manage</div>
                                                        <div className="skill-bar">
                                                            <div
                                                                className="skill-per"
                                                                id="90"
                                                                style={{ width: "90%" }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                    <div className="skill mb-30">
                                                        <div className="skill-name">Project Organize</div>
                                                        <div className="skill-bar">
                                                            <div
                                                                className="skill-per"
                                                                id="70"
                                                                style={{ width: "70%" }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="per-info">
                                                <h4>Personal Info</h4>
                                                <ul>
                                                    <li>
                                                        <div className="icon">
                                                            <i className="fa fa-envelope"></i>{" "}
                                                            <strong>Email</strong>
                                                        </div>
                                                        <div className="text">info@webexample.com</div>
                                                    </li>
                                                    <li>
                                                        <div className="icon">
                                                            <i className="fa fa-phone"></i>{" "}
                                                            <strong>Phone</strong>
                                                        </div>
                                                        <div className="text">980-786-098-09</div>
                                                    </li>
                                                    <li>
                                                        <div className="icon">
                                                            <i className="fa fa-map-marker-alt"></i>
                                                            <strong>Address</strong>
                                                        </div>
                                                        <div className="text">
                                                            12/A, Miranda City Hall, NYC
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="icon">
                                                            <i className="fa fa-globe"></i>
                                                            <strong>Website</strong>
                                                        </div>
                                                        <div className="text">www.webexample.com</div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First