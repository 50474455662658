import React, { useState, useEffect } from "react";
import axios from "axios"; import Bredcrumb from '../HomeMain/Bredcrumb'
import Appointment from '../HomeMain/Appointment'
import Testimonial from '../HomeMain/Testimonial'
import Service from '../HomeMain/Service'
import { useGlobalState } from "../../useGlobalState";
import { baseURL } from "../../App";


const Main = () => {

  const { globalState, setGlobalState } = useGlobalState();

  const [hizmet, setHizmet] = useState([]);

  useEffect(() => {
    try {
      axios
        .get(`${baseURL}items/hizmet?fields=*.*`)
        .then((response) => {
          // Verinin doğru formatta olup olmadığını kontrol et
          // Veriyi state'e set etme
          setHizmet(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching data from Directus:", error);
        });
    } catch (error) {
      console.error("An error occurred in useEffect:", error);
    }
  }, []);


  return (
    <>
      <Bredcrumb title={hizmet && hizmet.translations?.filter(e => e.languages_code == globalState.lang)[0]?.baslik} 
      subtitle={hizmet && hizmet.translations?.filter(e => e.languages_code == globalState.lang)[0]?.baslik} />

      <div className="service-main-block">
        <Service />
      </div>
      <Appointment />
      {/* <div className="testimonial-block">
        <Testimonial />
      </div> */}
    </>
  )
}

export default Main