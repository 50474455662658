import React, { useState, useEffect } from "react";
import Appointment from "../HomeMain/Appointment";
import Bredcrumb from "../HomeMain/Bredcrumb";
import Map from "../Mencontact/Map";
import axios from "axios";
import { baseURL } from "../../App";

import { useGlobalState } from "../../useGlobalState";


const Main = () => {
  const { globalState, setGlobalState } = useGlobalState();

  const [iletisim, setIletisim] = useState([]);

  const fetchContactData = async () => {
    try {
      const response = await axios.get(`${baseURL}items/iletisim?fields=*.*`);
      // Verinin doğru formatta olup olmadığını kontrol et
      if (response.data) {
        // Veriyi state'e set etme
        setIletisim(response.data.data);
      } else {
        console.error("Invalid data format received from Directus.");
      }
    } catch (error) {
      console.error("Error fetching data from Directus:", error);
    }
  };


  useEffect(() => {
    fetchContactData();
  }, []);

  return (
    <>
      <Bredcrumb title={iletisim && iletisim.translations?.filter(e => e.languages_code == globalState.lang)[0]?.baslik} subtitle={iletisim && iletisim.translations?.filter(e => e.languages_code == globalState.lang)[0]?.baslik} />

      <Appointment />
      <Map />
    </>
  );
};

export default Main;
