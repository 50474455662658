import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import BGImg from "../../assets/img/bg/footer-bg.png";
import Img2 from "../../assets/img/icon/ft-icon01.png";
import Footer from "../../assets/img/icon/ft-icon02.png";
import Footer1 from "../../assets/img/icon/ft-icon03.png";
import { baseURL } from "../../App";
import axios from "axios";
import { useGlobalState } from "../../useGlobalState";



const Main = () => {

  const [menuItems, setMenuItems] = useState([]);
  const [saatler, setSaatler] = useState([]);
  const [altbolum, setAltBolum] = useState([]);
  const [iletisim, setIletisim] = useState([]);
  const [Ayarlar, setAyarlar] = useState([]);


  const { globalState, setGlobalState } = useGlobalState();



  useEffect(() => {
    try {
      axios.get(`${baseURL}items/header?fields=*.*`)
        .then((response) => {
          // Verinin doğru formatta olup olmadığını kontrol et
          if (response.data) {
            // Veriyi state'e set etme
            setMenuItems(response.data.data);
          } else {
            console.error("Invalid data format received from Directus.");
          }
        })
        .catch((error) => {
          console.error("Error fetching data from Directus:", error);
        });
    } catch (error) {
      console.error("An error occurred in useEffect:", error);
    }
  }, []);


  const fetchContactData = async () => {
    try {
      const response = await axios.get(`${baseURL}items/iletisim?fields=*.*`);
      // Verinin doğru formatta olup olmadığını kontrol et
      if (response.data) {
        // Veriyi state'e set etme
        setIletisim(response.data.data);
      } else {
        console.error("Invalid data format received from Directus.");
      }
    } catch (error) {
      console.error("Error fetching data from Directus:", error);
    }
  };

  const fetchSaatData = async () => {
    try {
      const response = await axios.get(`${baseURL}items/saatler?fields=*.*`);
      // Verinin doğru formatta olup olmadığını kontrol et
      if (response.data) {
        // Veriyi state'e set etme
        setSaatler(response.data.data);
      } else {
        console.error("Invalid data format received from Directus.");
      }
    } catch (error) {
      console.error("Error fetching data from Directus:", error);
    }
  };


  const fetchAltBlolumData = async () => {
    try {
      const response = await axios.get(`${baseURL}items/altbolum?fields=*.*`);
      // Verinin doğru formatta olup olmadığını kontrol et
      if (response.data) {
        // Veriyi state'e set etme
        setAltBolum(response.data.data);
      } else {
        console.error("Invalid data format received from Directus.");
      }
    } catch (error) {
      console.error("Error fetching data from Directus:", error);
    }
  };



  const fetchLogoData = async () => {
    try {
      const response = await axios.get(`${baseURL}items/Ayarlar?fields=*.*`);
      // Verinin doğru formatta olup olmadığını kontrol et
      if (response.data) {
        // Veriyi state'e set etme
        setAyarlar(response.data.data);
      } else {
        console.error("Invalid data format received from Directus.");
      }
    } catch (error) {
      console.error("Error fetching data from Directus:", error);
    }
  };


  useEffect(() => {
    fetchContactData();
    fetchSaatData();
    fetchAltBlolumData();
    fetchLogoData();

  }, []);

  return (
    <>
      <footer className="footer-bg footer-p pt-90" style={{ backgroundColor: "#252525", backgroundImage: `url(${BGImg})` }}>
        <div className="footer-top">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-4 col-lg-4 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title mb-40">
                    <img src={`${baseURL}assets/${Ayarlar.logo?.id}`} alt="" />

                  </div>
                  <div className="footer-link">

                    <p>{altbolum && altbolum.translations?.filter(e => e.languages_code == globalState.lang)[0]?.title}</p>

                    <div className="f-contact">
                      <ul>
                        <li>
                          <div className="contact-box">
                            <div className="icon">
                              <img src={Img2} alt="logo" />
                            </div>
                            <div className="text">
                              <p>{saatler && saatler.translations?.filter(e => e.languages_code == globalState.lang)[0]?.altbaslik}</p>
                              <p>{saatler && saatler.translations?.filter(e => e.languages_code == globalState.lang)[0]?.yazi} </p>

                              {/* <strong>Pazartesi - Cuma:</strong>
                              <p>9:00 - 22:00</p>
                              <strong>Cumartesi - Pazar:</strong>
                              <p>9:00 - 20:00</p> */}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-2 col-lg-2 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>{altbolum && altbolum.translations?.filter(e => e.languages_code == globalState.lang)[0]?.menubaslik}</h2>
                  </div>
                  <div className="footer-link">
                    <ul>
                      {menuItems && menuItems.map((e) => (
                        <li key={e.id} className="has-sub">
                          <>
                            <Link to={e?.link}>{e?.translations?.filter(b => b.languages_code === globalState.lang)[0]?.title}</Link>
                          </>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>{altbolum && altbolum.translations?.filter(e => e.languages_code == globalState.lang)[0]?.bulten}</h2>
                  </div>
                  <div className="footer-link">
                    <div
                      className="subricbe p-relative"
                      data-animation="fadeInDown"
                      data-delay=".4s"
                    >
                      <form
                        action="news-mail.php"
                        method="post"
                        className="contact-form "
                      >
                        <input
                          type="text"
                          id="email2"
                          name="email2"
                          className="header-input"
                          placeholder={altbolum && altbolum.translations?.filter(e => e.languages_code == globalState.lang)[0]?.mail}
                          required
                        />
                        <button className="btn header-btn">
                          <i className="fas fa-location-arrow"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="footer-social mt-10">
                  {/* <a href="https://www.facebook.com/" title="Facebook">
                    <i className="fab fa-facebook-f"></i> 
                  </a>*/}
                  <a href="https://www.instagram.com/heysirbeauty/" target="_blank" title="Instagram">
                    <i className="fab fa-instagram"></i>
                  </a>

                  {/* <a href="https://www.instagram.com/heysirbeauty/" title="Instagram">
                    <i >{altbolum && altbolum.translations?.filter(e => e.languages_code == globalState.lang)[0]?.instagram}</i>
                  </a> */}
                  {/* <a href="https://twitter.com" title="Twitter">
                    <i className="fab fa-twitter"></i>
                  </a> */}
                </div>
              </div>
            </div>
            <div className="row align-items-center copyright-wrap">
              <div className="col-lg-6">
                <div className="copy-text text-center">
                  <Link to="https://yunsoftyazilim.com/" target="_blank">{altbolum && altbolum.translations?.filter(e => e.languages_code == globalState.lang)[0]?.copyright}</Link>
                  {/* Copyright &copy; 2022 <Link to="https://yunsoftyazilim.com/">Yunsoft Yazılım</Link>. All rights
                  reserved. */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="copay-contact">
                  <ul>
                    <li>
                      <div className="c-contact">
                        <div className="icon">
                          <img src={Footer} alt="img" />
                        </div>
                        <div className="text">
                          <span>{iletisim && iletisim.translations?.filter(e => e.languages_code == globalState.lang)[0]?.mail}</span>
                          <h3>{iletisim && iletisim.translations?.filter(e => e.languages_code == globalState.lang)[0]?.mailadres}</h3>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="c-contact">
                        <div className="icon">
                          <img src={Footer1} alt="img" />
                        </div>
                        <div className="text">
                          <span>{iletisim && iletisim.translations?.filter(e => e.languages_code == globalState.lang)[0]?.telefon}</span>
                          <h3>{iletisim && iletisim.translations?.filter(e => e.languages_code == globalState.lang)[0]?.no}</h3>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Main;
