import React from "react";
import Bredcrumb from "../../HomeMain/Bredcrumb";
import First from "../Menblogdeatils/First";

const Main = () => {
  return (
    <>
      <Bredcrumb title="Blog Details" subtitle="Blog Details" />
      <First/>
    </>
  );
};

export default Main;
