import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import BGIMG from "../../assets/img/bg/bdrc-bg.png";
import { baseURL } from "../../App";
import { useGlobalState } from "../../useGlobalState";

const Bredcrumb = ({ title, subtitle }) => {
  const { globalState } = useGlobalState();
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await axios.get(`${baseURL}items/header?fields=*.*`);
        if (response.data && Array.isArray(response.data.data)) {
          setMenuItems(response.data.data);
        } else {
          console.error("Invalid data format received from Directus.");
        }
      } catch (error) {
        console.error("Error fetching data from Directus:", error);
      }
    };

    fetchMenuItems();
  }, []);

  const getMenuItemTitle = (headerId) => {
    const menuItem = menuItems.find((item) => item.translations.some((t) => t.languages_code === globalState.lang && t.header_id === headerId));
    return menuItem?.translations.find((t) => t.languages_code === globalState.lang && t.header_id === headerId)?.title || "";
  };

  return (
    <section className="breadcrumb-area d-flex align-items-center" style={{ backgroundImage: `url(${BGIMG})` }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-12 col-lg-12">
            <div className="breadcrumb-wrap text-left">
              <div className="breadcrumb-title">
                <h2>{title}</h2>
                <div className="breadcrumb-wrap">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{getMenuItemTitle(1)}</Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        {subtitle}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bredcrumb;
