import React from "react";
import Bredcrumb from "../../HomeMain/Bredcrumb";
import First from "../Menteamdeatils/First";

const Main = () => {
  return (
    <>
      <Bredcrumb title="Team Details" subtitle="Team Details" />
      <First/>
    </>
  );
};

export default Main;
