import React from "react";
import Bredcrumb from "../../HomeMain/Bredcrumb";
import First from "../Menteam/First";

const Main = () => {

  return (
    <>
      <Bredcrumb title="Team" subtitle="Team" />
      <First/>
    </>
  );
};

export default Main;
